import {usePage} from "@inertiajs/vue3";

export function hasPermissions(permissions) {
    const auth = usePage().props.auth;
    const userPermissions = Object.values(auth.permissions);

    if (Array.isArray(permissions)) {
        return permissions.every(permission => userPermissions.includes(permission));
    } else {
        return userPermissions.includes(permissions);
    }
}
